"use client";

import jwtDecode from "jwt-decode";
import router from "next/router";
import { create } from "zustand";

import { getUserType } from "@/lib/utils";

interface AuthStore {
  ready: boolean;
  switchingRole: boolean;
  token: string;
  refreshToken: string;
  tokenInvalid: boolean;
  user: any;
  init: () => void;
  setUser: (user: any) => void;
  logout: (redirectHome?: boolean) => void;
  switchRole: () => void;
}

const useAuthStore = create<AuthStore>((set, get) => ({
  ready: false,
  switchingRole: false,
  token: "",
  refreshToken: "",
  tokenInvalid: false,
  user: null,

  init: () => {
    let user;
    try {
      const res = localStorage.getItem("user");
      user = res ? JSON.parse(res) : null;
    } catch {
      user = null;
    }

    const token = localStorage.getItem("authToken") || "";
    const refreshToken = localStorage.getItem("refreshToken") || "";

    let tokenInvalid = !token || user === null;

    if (!tokenInvalid) {
      try {
        const decodedToken: any = jwtDecode(String(token));
        tokenInvalid = decodedToken.exp * 1000 < Date.now();
      } catch (error) {
        tokenInvalid = true;
      }
    }

    if (tokenInvalid) {
      set({ tokenInvalid: true, ready: true });
    } else {
      set({
        token,
        refreshToken,
        ready: true,
        user,
        tokenInvalid,
      });
    }
  },

  setUser: (user: any) => {
    localStorage.setItem("user", JSON.stringify(user));
    localStorage.setItem("authToken", user.token);

    if (user.refresh_token) {
      localStorage.setItem("refreshToken", user.refresh_token);
    }

    get().init();
  },

  logout: (redirectHome = true) => {
    clearStorage();
    set(() => ({ token: "", refreshToken: "", user: null, ready: false }));
    if (redirectHome) {
      window.location.href = "/home";
    }
  },

  switchRole: () => {
    const userType = getUserType(get().user);
    set({ switchingRole: true });
    setTimeout(async () => {
      if (userType === "university") {
        await router.push("/home");
      } else {
        await router.push("/hosting/dashboard");
      }
      set({ switchingRole: false });
    }, 600);
  },
}));

export function clearStorage() {
  localStorage.removeItem("authToken");
  localStorage.removeItem("refreshToken");
  localStorage.removeItem("user");
  localStorage.removeItem("labProfileVerified");
}

export default useAuthStore;
